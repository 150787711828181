import 'bootstrap/dist/css/bootstrap.min.css';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet-async';
import Markdown from 'react-markdown';
import { useLocation, useNavigate } from 'react-router-dom';

import Disclaimer from '../../components/Disclaimer/Disclaimer';
import Footer from '../../components/Footer/Footer';
import { HelperRow } from '../../components/HelperRow/HelperRow';
import Loading from '../../components/Loading/Loading';
import NavbarWrapper from '../../components/NavBarWrapper/NavBarWrapper';
import { useDocument } from '../../hooks/hooks';
import styles from './Article.module.css';
import mdStyles from './Markdown.module.css';

const Article = ({ site_name, copyright, footer_logo }) => {
  // API handling
  const slug = useLocation().pathname;
  const history = useNavigate();
  const { data: data, isLoading } = useDocument(slug);
  const helmetSlug = slug
    .split('/')[2]
    .split('-')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
  if (!data) {
    history('/404');
  }
  if (isLoading) {
    return (
      <>
        <Helmet>
          <title>{helmetSlug} - {site_name}{' '} </title>
        </Helmet>
        <Loading color="#333333" />
      </>
    );
  }
  const full_current_url = window.location.href;

  let ldJson = {};
  if (data) {
    ldJson = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      headline: helmetSlug + ' - ' + site_name,
      author: {
        '@type': 'Person',
        name: site_name
      },
      publisher: {
        '@type': 'Organization',
        name: site_name,
        logo: {
          '@type': 'ImageObject',
          url: footer_logo
        }
      },
      datePublished: data['date_published'],
      description: data['description']
    };
  }

  function handleUrlClick() {
    window.location.href = 'https://example.com';
  }

  return (
    <>
      <Helmet>
        <title>{helmetSlug} - Oetzy </title>
        <meta name="description" content={data['description']}></meta>
        <meta name="keywords" content={data['primary_keyword'] + data['keywords']}></meta>
        <meta property="og:description" content={data['description']}></meta>
        <meta property="og:title" content={helmetSlug + ' - Oetzy'}></meta>
        <meta property="og:url" content={full_current_url}></meta>
        <meta property="og:image"></meta>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>
      {data && (
        <div>
          <NavbarWrapper text={data['call_to_action']} />
          <br />
          <a className={styles.category} href={data['cta_link'] + '?ref=' + slug.split('/')[2]}>
            {data['category']}
          </a>
          <div>
            <Button size="sm" className={styles.btnDark} onClick={handleUrlClick}>
              REPORT A CONTENT ISSUE
            </Button>
          </div>
          <p></p>
          <div id="123246-1"><script src="//ads.themoneytizer.com/s/gen.js?type=1"></script><script src="//ads.themoneytizer.com/s/requestform.js?siteId=123246&formatId=1"></script></div>
          <Markdown className={classNames(mdStyles.h1, mdStyles.h2, mdStyles.p)}>
            {data['content']}
          </Markdown>
          <h3 className={styles.sources}>Sources : </h3>
          <ul>
            {data['sources'].map((source) => (
              <li key={source}>
                <a className={styles.source} href={source} key={source} rel="nofollow">
                  {source}
                </a>
              </li>
            ))}
          </ul>
          <h5 className={styles.callToAction}>{data['call_to_action']}</h5>
          <HelperRow text="Let us help you today!" url={data['cta_link']} />
          <Disclaimer />
          <Footer copyright={copyright} footer_logo={footer_logo} />
        </div>
      )}
    </>
  );
};

export default Article;

Article.propTypes = {
  copyright: PropTypes.string,
  footer_logo: PropTypes.string
};
