import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import styles from './TopNavBar.module.css';

const TopNavBar = ({ logo_url, site_url }) => {
  function handleUrlClick() {
    window.location.href = 'https://example.com';
  }

  return (
    <div className={styles.navbar}>
      <div className={styles.container}>
        <a className={styles.navbarBrand} href={site_url}>
          <img src={logo_url} alt="logo" className={styles.topImage} />
        </a>
        <ul className={styles.listItemAlign}>
          <li>
            <Button variant="light" className={styles.buttonAlign} onClick={handleUrlClick}>
              We can help!
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TopNavBar;

TopNavBar.propTypes = {
  logo_url: PropTypes.string,
  site_url: PropTypes.string
};
