import PropTypes from 'prop-types';

import styles from './HelperRow.module.css';

export const HelperRow = ({ text, url }) => {
  return (
    <div className={styles.helperRow}>
      <div className={styles.helperCol}>
        <h4>
          <a href={url} className={styles.button}>
            {text}
          </a>
        </h4>
      </div>
    </div>
  );
};

HelperRow.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string
};
