import styles from './Disclaimer.module.css';

const Disclaimer = () => {
  return (
    <>
      <p className={styles.disclaimer}>
        Disclaimer: The content in this article is provided for general informational purposes only.
        It may not be accurate, complete, or up-to-date and should not be relied upon as legal,
        financial, or other professional advice. Any actions or decisions taken based on this
        information are the sole responsibility of the user. Oetzy expressly disclaims any liability
        for any loss, damage, or harm that may result from reliance on this information. Please note
        that this article may contain affiliate endorsements and advertisements. The inclusion of
        such does not indicate an endorsement or approval of the products or services linked. Oetzy
        does not accept responsibility for the content, accuracy, or opinions expressed on any
        linked website. When you engage with these links and decide to make a purchase, we may
        receive a percentage of the sale. This affiliate commission does not influence the price you
        pay, and we disclaim any responsibility for the products or services you purchase through
        these links.
      </p>
      &nbsp;
      <a
        href="https://oetzy.com/oetzy-terms-and-conditions-user-agreement-policy.html"
        className={styles.fullDisclaimer}
      >
        Full Disclaimer
      </a>
      &nbsp;
    </>
  );
};

export default Disclaimer;
