import PropTypes from 'prop-types';
import React from 'react';

import styles from './NavBarWrapper.module.css';

const NavbarWrapper = ({ text }) => {
  return (
    <>
      <div className={styles.navbarWrapper} role="button" tabIndex="0">
        {text}
      </div>
    </>
  );
};

NavbarWrapper.propTypes = {
  text: PropTypes.string
};

export default NavbarWrapper;
