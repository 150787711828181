import { useQuery } from 'react-query';

import { API_URL, getDocuments } from '../utils/API';

export const useDocument = (slug) => {
  const documentUrl = `${API_URL}/getDocumentDetail?slug=${slug.split('/')[2]}`;
  return useQuery(['documents', slug], () => getDocuments(documentUrl), {
    staleTime: Infinity,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    notifyOnChangeProps: ['error', 'data']
  });
};
