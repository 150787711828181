import PropTypes from 'prop-types';

// import statcareLogo from '../../assets/img/logo.png';
import styles from './Footer.module.css';

const Footer = ({ copyright, footer_logo }) => {
  return (
    <>
      <div className={styles.footer}>
        <img src={footer_logo} alt="logo" className={styles.img} />
        <p className={styles.text}>&#169; {copyright}</p>
      </div>
      <p className={styles.text}>
        In our goal to answer your questions as quick as possible, we use the assistance of a large
        language model wherever applicable. If there are any errors or concerns, please bring it to
        our attention by emailing us or using the report content button above.
      </p>
    </>
  );
};

export default Footer;

Footer.propTypes = {
  copyright: PropTypes.string,
  footer_logo: PropTypes.string
};
